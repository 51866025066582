import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import CaseStudyContentBody from "../../components/pages/CaseStudyDetails/CaseStudyContentBody"

const SimplifyingFinancialManagementForAFreelanceGraphicDesigner = () => {
  return (
    <Layout>
      <Seo
        title="Simplifying Financial Management For a Freelance Graphic Designer"
        description={`See how we simplified financial management for a freelance graphic designer. Explore the strategies and tools used to streamline their financial processes and improve efficiency.`}
      />

      {/* This slug is matched with blogs in Content */}
      <CaseStudyContentBody
        slug={`simplifying-financial-management-for-a-freelance-graphic-designer`}
      />
    </Layout>
  )
}

export default SimplifyingFinancialManagementForAFreelanceGraphicDesigner
